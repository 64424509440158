<template>
  <div style="width: 1200px; margin: auto">
    <div class="searchBox_top">
      <div
        class="item"
        :style="{ background: searchTopBg }"
        :class="{ itemEn: LOCALE == 'en' }"
      >
        <img :src="search_icon.start" alt="" />
        <span>{{ $t("departurePorts") }}</span>
        <portSearch
          v-model="query_search.polName"
          @codeChange="polCodeCodeChange"
          :placeTitle="$t('pleasePorts')"
          :searchTopBg="searchTopBg"
        ></portSearch>
      </div>
      <div
        class="item"
        :style="{ background: searchTopBg }"
        :class="{ itemEn: LOCALE == 'en' }"
      >
        <img :src="search_icon.end" alt="" />
        <span>{{ $t("destinations") }}</span>
        <portSearch
          v-model="query_search.podName"
          @codeChange="podCodeCodeChange"
          :placeTitle="$t('pleaseTions')"
          :searchTopBg="searchTopBg"
          :portType="'pod'"
        ></portSearch>
      </div>
      <div class="item" :style="{ background: searchTopBg }">
        <img :src="search_icon.time" alt="" />
        <span>{{ $t("time") }}</span>
        <el-date-picker
          :picker-options="pickerOptions"
          :clearable="false"
          :class="LOCALE == 'en' ? 'timeEn_picker' : 'time_picker'"
          v-model="form.date"
          type="date"
          :placeholder="$t('selectDate')"
          @change="handleDate"
        >
        </el-date-picker>
        <span style="padding-right: 10px; color: #7c7c7c; font-size: 14px">
          <img
            :src="search_icon.leave"
            alt=""
            style="width: 14px; margin-right: 4px"
          />
          {{ $t("departures") }}
        </span>
      </div>
      <el-button class="btn"  :class="{ btnEn: LOCALE == 'en' }" type="primary" @click="handleSearch" :style="searchBtn">{{
        $t("scheduleCheck")
      }}</el-button>
    </div>
    <div class="searchBox_middle" v-if="useType == 1">
      <el-tabs
        style="margin-top: 16px"
        v-model="activeName"
        size="small"
        type="card"
        @tab-click="handleSearch"
      >
        <el-tab-pane
          v-for="(item, i) in weekArr"
          :key="i"
          :label="
            item.label +
            '  ' +
            secondFormat(item.start, 'L') +
            ' - ' +
            secondFormat(item.end, 'L')
          "
          :name="i.toString()"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <!--    <div class="searchBox_bottom" v-if="useType == 1">-->
    <!--      <div class="top">-->
    <!--        <el-popover-->
    <!--            placement="bottom"-->
    <!--            width="160"-->
    <!--        >-->
    <!--          <div>-->
    <!--            <el-checkbox-group v-model="form.check_ship_list">-->
    <!--              <el-checkbox v-for="(item,i) in shipArr" :key='i' :label="item.value">{{ item.label }}</el-checkbox>-->
    <!--            </el-checkbox-group>-->
    <!--          </div>-->
    <!--          <div slot="reference" class="item">-->
    <!--            <span>船公司</span>-->
    <!--            <i class="el-icon-caret-bottom"></i>-->
    <!--          </div>-->
    <!--        </el-popover>-->

    <!--        <el-popover-->
    <!--            placement="bottom"-->
    <!--            width="160"-->
    <!--        >-->
    <!--          <div>-->
    <!--            <el-checkbox-group v-model="form.check_way_list">-->
    <!--              <el-checkbox v-for="(item,i) in wayArr" :key='i' :label="item.value">{{ item.label }}</el-checkbox>-->
    <!--            </el-checkbox-group>-->
    <!--          </div>-->
    <!--          <div slot="reference" class="item">-->
    <!--            <span>直达中转</span>-->
    <!--            <i class="el-icon-caret-bottom"></i>-->
    <!--          </div>-->
    <!--        </el-popover>-->

    <!--        <el-popover-->
    <!--            placement="bottom"-->
    <!--            width="160"-->
    <!--        >-->
    <!--          <div>-->
    <!--            <el-checkbox-group v-model="form.check_mt_list">-->
    <!--              <el-checkbox v-for="(item,i) in mtArr" :key='i' :label="item.value">{{ item.label }}</el-checkbox>-->
    <!--            </el-checkbox-group>-->
    <!--          </div>-->
    <!--          <div slot="reference" class="item">-->
    <!--            <span>码头</span>-->
    <!--            <i class="el-icon-caret-bottom"></i>-->
    <!--          </div>-->
    <!--        </el-popover>-->

    <!--        <el-popover-->
    <!--            placement="bottom"-->
    <!--            width="160"-->
    <!--        >-->
    <!--          <div>-->
    <!--            <el-checkbox-group v-model="form.check_day_list">-->
    <!--              <el-checkbox v-for="(item,i) in dayArr" :key='i' :label="item.value">{{ item.label_zh }}</el-checkbox>-->
    <!--            </el-checkbox-group>-->
    <!--          </div>-->
    <!--          <div slot="reference" class="item">-->
    <!--            <span>班期</span>-->
    <!--            <i class="el-icon-caret-bottom"></i>-->
    <!--          </div>-->
    <!--        </el-popover>-->

    <!--      </div>-->
    <!--      <div class="bottom">-->
    <!--        <div>-->
    <!--          <el-tag-->
    <!--              class="tag_item"-->
    <!--              @close='tag_del(i)'-->
    <!--              :disable-transitions='true'-->
    <!--              v-for="(tag,i) in tags"-->
    <!--              :key="tag.name"-->
    <!--              :title="tag.name"-->
    <!--              closable>-->
    <!--            {{ tag.name.length > 9 ? tag.name.slice(0, 9) + '...' : tag.name }}-->
    <!--          </el-tag>-->
    <!--        </div>-->
    <!--        <el-link :underline="false" type="info" @click="clear_filter">清空筛选条件</el-link>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="searchBox_isTransit" v-if="useType == 1">
      <el-radio v-model="form.isTransit" label="0" @change="handleSearch">{{
        $t("direct")
      }}</el-radio>
      <el-radio v-model="form.isTransit" label="1" @change="handleSearch">{{
        $t("transit")
      }}</el-radio>
    </div>
  </div>
</template>

<script>
import { secondFormat } from "@/basePlugins/filters.js";
import { days } from "@/baseUtils/enumValue.js";
import portSearch from "~/baseComponents/baseShippingDate/components/portSearch";

export default {
  props: {
    // 1是全部,2是只有头部
    useType: {
      type: String | Number,
      default: 1,
    },
    searchTopBg: {
      type: String,
      default: "#fff",
    },
    searchBtn: {
      type: String,
      default: "",
    },
  },
  components: { portSearch },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          let nowWeek = moment().weekday(1).unix() * 1000;
          return (
            time.getTime() < Date.now() - 60 * 1000 * 60 * 24 ||
            time.getTime() > nowWeek + 60 * 1000 * 60 * 24 * 27
          );
        },
      },
      form: {
        polCode: "",
        podCode: "",
        date: this.$moment(),
        check_ship_list: [],
        check_way_list: [],
        check_mt_list: [],
        check_day_list: [],
        isTransit: "0",
        date_type: "etd",
        week: {},
      },
      activeName: "0",
      weekArr: [],
      cities: [],
      shipArr: [],
      wayArr: [
        {
          value: 1,
          label: this.$t("transit"),
        },
        {
          value: 2,
          label: this.$t("direct"),
        },
      ],
      mtArr: [],
      dayArr: days,
      tags: [],
      query_search: {
        polName: null,
        podName: null,
      },
      search_icon: {},
    };
  },
  mounted() {
    let arr = [
      {
        start:
          "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/chaun.jpg",
        end: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/chaun.jpg",
        time: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/clock.jpg",
        leave:
          "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffaApplent/radio_button.png",
      },
      {
        start:
          "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/search_start.png",
        end: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/search_start.png",
        time: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/search_time.png",
        leave:
          "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/common/search_leave.png",
      },
      {
        start: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/tif_search_start.png',
        end: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/tif_search_start.png',
        time: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/tif_search_time.png',
        leave: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/tif_radio_button.png'
      }
    ];
    if (this.PJSource == 7) {
      // 上海货代协会
      this.search_icon = arr[0];
    } else if (this.PJSource == 12) {
      // 物贸联合会
      this.search_icon = arr[1];
    } else if(this.PJSource == 30){
      this.search_icon = arr[2]
    }else{
      // 默认用上海货代协会的红色主题
      this.search_icon = arr[0];
    }
    // console.log(this.PJSource);
    this.createWeekArr();
    this.initForm();
  },
  watch: {
    "form.check_ship_list": function (value) {
      let str = "";
      for (var i of value) {
        this.shipArr.forEach((item) => {
          if (i == item.value) {
            str += item.label + ",";
          }
        });
      }
      str = str.slice(0, -1);
      let item = {
        type: "check_ship_list",
        name: str,
      };
      this.createTabs(item);
    },
    "form.check_way_list": function (value) {
      let str = "";
      for (var i of value) {
        this.wayArr.forEach((item) => {
          if (i == item.value) {
            str += item.label + ",";
          }
        });
      }
      str = str.slice(0, -1);
      let item = {
        type: "check_way_list",
        name: str,
      };
      this.createTabs(item);
    },
    "form.check_mt_list": function (value) {
      let str = "";
      for (var i of value) {
        this.mtArr.forEach((item) => {
          if (i == item.value) {
            str += item.label + ",";
          }
        });
      }
      str = str.slice(0, -1);
      let item = {
        type: "check_mt_list",
        name: str,
      };
      this.createTabs(item);
    },
    "form.check_day_list": function (value) {
      let str = "";
      for (var i of value) {
        this.dayArr.forEach((item) => {
          if (i == item.value) {
            str += item.label_zh + ",";
          }
        });
      }
      str = str.slice(0, -1);
      let item = {
        type: "check_day_list",
        name: str,
      };
      this.createTabs(item);
    },
  },
  methods: {
    secondFormat,
    tag_del(i) {
      this.form[this.tags[i].type] = [];
    },
    createTabs(item) {
      if (this.tags.length) {
        let index = this.tags.findIndex((it, i) => {
          return it.type == item.type;
        });
        if (index == -1) {
          if (item.name) {
            this.tags.push(item);
          } else {
            this.tags.splice(index, 1);
          }
        } else {
          if (item.name) {
            this.tags[index].name = item.name;
          } else {
            this.tags.splice(index, 1);
          }
        }
      } else {
        if (item.name) {
          this.tags.push(item);
        }
      }
    },
    clear_filter() {
      this.form.check_ship_list = [];
      this.form.check_way_list = [];
      this.form.check_mt_list = [];
      this.form.check_day_list = [];
      this.form.activeName = "-1";
    },
    createWeekArr() {
      let week = this.$moment(new Date()).day();
      if (week == 0) {
        week = 7;
      }
      // 本周-结束时间
      let week1_start = moment().startOf("week").unix() + 86400;
      let week1_end = moment().endOf("week").unix() + 86400;

      // 下一周
      let week2_start = moment().add(1, "w").startOf("week").unix() + 86400;
      let week2_end = moment().add(1, "w").endOf("week").unix() + 86400;

      // 下二周
      let week3_start = moment().add(2, "w").startOf("week").unix() + 86400;
      let week3_end = moment().add(2, "w").endOf("week").unix() + 86400;

      // 下三周
      let week4_start = moment().add(3, "w").startOf("week").unix() + 86400;
      let week4_end = moment().add(3, "w").endOf("week").unix() + 86400;
      this.weekArr = [
        {
          label: this.$t("thisWeek"),
          start: week1_start,
          end: week1_end,
        },
        {
          label: this.$t("nextWeek"),
          start: week2_start,
          end: week2_end,
        },
        {
          label: this.$t("nextTwoWeeks"),
          start: week3_start,
          end: week3_end,
        },
        {
          label: this.$t("nextThreeWeeks"),
          start: week4_start,
          end: week4_end,
        },
      ];
    },
    handleSearch() {
      if (!this.IS_LOGIN) {
        return this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
      }
      this.form.week = Object.assign({}, this.weekArr[Number(this.activeName)]);
      this.$emit("handleSearch", this.form, this.query_search);
    },
    polCodeCodeChange(code) {
      this.form.polCode = code;
    },
    podCodeCodeChange(code) {
      this.form.podCode = code;
    },
    initForm() {
      if (JSON.stringify(this.$route.query) != "{}") {
        let from = Object.assign({}, this.$route.query);
        from.date = this.$moment(from.date * 1000);
        this.form.polCode = from.polCode;
        this.form.podCode = from.podCode;
        this.form.date = from.date;
        this.query_search.polName = from.polName;
        this.query_search.podName = from.podName;
      }
      if (this.form.date) {
        this.handleDate();
      }
      if (this.form.polCode && this.form.podCode) {
        this.form.week = Object.assign(
          {},
          this.weekArr[Number(this.activeName)]
        );
        this.$emit("handleSearch", this.form, this.query_search);
      }
    },
    handleDate() {
      if (
        this.weekArr[0].start * 1000 <= this.form.date &&
        this.weekArr[0].end * 1000 > this.form.date
      ) {
        this.activeName = "0";
      }
      if (
        this.weekArr[1].start * 1000 <= this.form.date &&
        this.weekArr[1].end * 1000 > this.form.date
      ) {
        this.activeName = "1";
      }
      if (
        this.weekArr[2].start * 1000 <= this.form.date &&
        this.weekArr[2].end * 1000 > this.form.date
      ) {
        this.activeName = "2";
      }
      if (
        this.weekArr[3].start * 1000 <= this.form.date &&
        this.weekArr[3].end * 1000 > this.form.date
      ) {
        this.activeName = "3";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.searchBox_top {
  display: flex;
  justify-content: space-between;
  margin: 16px 0;

  .item {
    width: 336px;
    height: 58px;

    span {
      padding-left: 5px;
      padding-right: 20px;
      font-size: 16px;
      font-weight: 800;
      color: #333333;
      line-height: 22px;
    }

    /deep/ .el-input__inner {
      border: none;
    }
  }
  .itemEn {
    width: 320px;
  }
}

.btn {
  width: 186px;
  height: 58px;
  border-radius: 0;
  font-size: 16px;
  font-weight: 800;
  word-break: break-all;
}
.btnEn{
  width: 235px;
}
.time_picker {
  width: 186px;
  /deep/ .el-input__inner {
    background: var(--searchTopBg) !important;
  }
}
.timeEn_picker {
  width: 140px;
  /deep/ .el-input__inner {
    background: var(--searchTopBg) !important;
  }
}
.searchBox_top > div {
  display: flex;
  align-items: center;
  padding-left: 8px;
}

.searchBox_middle {
  height: 59px;
  overflow: hidden;
}

.searchBox_bottom {
  background: #fff;
  padding: 0 20px;
  position: relative;
  top: -4px;
  z-index: 5;

  .top {
    height: 68px;
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    align-items: center;

    .item {
      width: 146px;
      cursor: pointer;
    }
  }

  .bottom {
    height: 67px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

/deep/ .el-checkbox {
  padding: 5px 0;
}

/deep/ .el-checkbox-group {
  display: flex;
  flex-direction: column;
}

.tag_item {
  cursor: pointer;
  margin-right: 16px;
}
.searchBox_isTransit {
  margin-top: 10px;
}
</style>