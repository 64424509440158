<template>
  <div>
    <carouselBanner paddingTop="24%" :bannerType="6" />
    <div class="baseShippingDate">
      <search @handleSearch="handleSearch"></search>
      <div class="myTable" v-loading="loading">
        <div class="myThead el-button--primary">
          <div class="th th1">{{ $t("bigShipETD") }}</div>
          <div class="th th2">{{ $t("totalVoyage") }}</div>
          <div class="th th3">{{ $t("direct") }}/{{ $t("transit") }}</div>
          <div class="th th4">
            {{ $t("shippingCompany") }}（{{ $t("routeCode") }}）
          </div>
          <div class="th th5">{{ $t("bigShipName") }}/{{ $t("voyage") }}</div>
          <div class="th th6"></div>
        </div>
        <item
          v-for="(item, i) in tableData"
          :key="i"
          :item="item"
          @handleViewDetail="handleViewDetail"
        ></item>
        <div v-if="tableData.length == 0 && !loading">
          <noDataImg />
        </div>
      </div>
      <div class="block" v-if="total != 0">
        <el-pagination
          background
          @current-change="pageChange"
          :hide-on-single-page="true"
          :current-page="changePage"
          layout="prev, pager, next"
          :total="total"
          :page-size="limit"
        ></el-pagination>
      </div>
      <el-dialog
        :visible.sync="dialogVisible"
        width="600px"
        :close-on-click-modal="false"
      >
        <detail :row="row"></detail>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import carouselBanner from "@/baseComponents/carouselBanner";
import search from "./components/search.vue";
import item from "./components/item.vue";
import detail from "./components/detail.vue";
import { weeksFormat } from "~sif/plugins/filters";

export default {
  components: {
    search,
    item,
    detail,
    carouselBanner,
  },
  data() {
    return {
      loading: false,
      total: 4,
      limit: 10,
      changePage: 1,
      tableData: [],
      form: {
        polCode: "",
        podCode: "",
        start_date: "",
        end_date: "",
        isTransit: "0",
        date_type: "etd",
        date: this.$moment(),
      },
      dialogVisible: false,
      row: {},
    };
  },
  methods: {
    handleSearch(value) {
      this.form.polCode = value.polCode;
      this.form.podCode = value.podCode;
      this.form.start_date = value.week.start;
      this.form.end_date = value.week.end;
      this.form.isTransit = value.isTransit;
      this.form.date_type = value.date_type;
      this.form.date = value.date;
      if (!this.form.polCode)
        return this.$message.warning(this.$t("pleasePorts"));
      if (!this.form.podCode)
        return this.$message.warning(this.$t("pleaseTions"));
      this.changePage = 1;
      this.getList();
    },
    async getList() {
      let params = {
        polCode: this.form.polCode,
        podCode: this.form.podCode,
        start_date: this.$moment(this.form.start_date * 1000).format(
          "YYYY-MM-DD"
        ),
        end_date: this.$moment(this.form.end_date * 1000).format("YYYY-MM-DD"),
        date_type: this.form.date_type,
        date: this.$moment(this.form.date).format("YYYY-MM-DD"),
        pageNum: this.changePage - 1,
        pageSize: this.limit,
        isTransit: this.form.isTransit,
      };
      this.loading = true;
      let res = await this.$store.dispatch(
        "baseStore/harbour_sailing_date",
        params
      );
      this.loading = false;
      if (res.success) {
        res.data.data.forEach((item) => {
          // 大船ETD
          item.ETD = this.$moment(item.etd).day();
          // 航程
          item.len =
            this.$moment(item.staticEta).diff(
              this.$moment(item.staticEtd),
              "day"
            ) + this.$t("days");
          // 共仓船公司
          item.wharf = item.shareCabins
            .map((ee, vv) => {
              let eee = `${ee.carrier}(${ee.disPlayName || ee.routeCode})`;
              return eee;
            })
            .join("   |   ");
          // 如果是中转港，那么有几程
          if (item.isTransit == 1) {
            if (item.transitPortEn) item.transitNum = 2;
            if (item.secondTransitPortEn) item.transitNum = 3;
            if (item.thirdTransitPortEn) item.transitNum = 4;
          }
          // 计算起运港预计是否提前
          let diffTime = this.$moment(item.etd).diff(
            this.$moment(item.staticEtd),
            "seconds"
          );
          if (diffTime > 0) {
            item.sevenData = this.$t("delay");
            item.sevenDataid = 1;
          } else if (diffTime == 0) {
            item.sevenData = this.$t("onTime");
            item.sevenDataid = 2;
          } else if (diffTime < 0) {
            item.sevenData = this.$t("advance");
            item.sevenDataid = 3;
          }
          //详情里 ，起运港计划l离港
          item.polInfo1 = `${this.$moment(item.staticEtd).format(
            "MM-DD"
          )}(${weeksFormat(
            this.$moment(item.staticEtd).day(),
            this.LOCALE
          )}) ${this.$moment(item.staticEtd).format("HH: mm")}`;
          //详情里 起运港最新预计
          item.polInfo2 = `${this.$moment(item.etd).format(
            "MM-DD"
          )}(${weeksFormat(
            this.$moment(item.etd).day(),
            this.LOCALE
          )}) ${this.$moment(item.etd).format("HH: mm")}`;

          //详情里 ，目的港计划到港
          item.podInfo1 = `${this.$moment(item.staticEta).format(
            "MM-DD"
          )}(${weeksFormat(
            this.$moment(item.staticEta).day(),
            this.LOCALE
          )}) ${this.$moment(item.staticEta).format("HH: mm")}`;
          //详情里 起运港最新预计
          item.podInfo2 = `${this.$moment(item.eta).format(
            "MM-DD"
          )}(${weeksFormat(
            this.$moment(item.eta).day(),
            this.LOCALE
          )}) ${this.$moment(item.eta).format("HH: mm")}`;

          // 计算目的港预计是否提前
          let diffTime2 = this.$moment(item.eta).diff(
            this.$moment(item.staticEta),
            "seconds"
          );
          if (diffTime2 > 0) {
            item.sevenData2 = this.$t("delay");
            item.sevenDataid2 = 1;
          } else if (diffTime2 == 0) {
            item.sevenData2 = this.$t("onTime");
            item.sevenDataid2 = 2;
          } else if (diffTime2 < 0) {
            item.sevenData2 = this.$t("advance");
            item.sevenDataid2 = 3;
          }
        });
        this.tableData = res.data.data;
        this.total = res.data.data_total;
      }
    },
    handleViewDetail(row) {
      this.row = Object.assign({}, row);
      this.dialogVisible = true;
    },
    pageChange(index) {
      this.changePage = index;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.baseShippingDate {
  width: 1200px;
  margin: auto;
  background: #fafafa;
}

.myTable {
  min-height: 700px;
  position: relative;
  padding-bottom: 20px;
}

.myThead {
  margin-top: 24px;
  height: 50px;
  // background: #ff5155;
  padding-left: 20px;
  display: flex;

  .th {
    line-height: 50px;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
  }

  .th1 {
    width: 128px;
  }

  .th2 {
    width: 120px;
  }

  .th3 {
    width: 191px;
  }

  .th4 {
    width: 360px;
  }

  .th5 {
    width: 282px;
  }

  .th6 {
    flex: 1;
  }
}

.block {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
}
</style>