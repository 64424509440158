<template>
  <div class="itemBox">
    <div class="td td1 textStyle_b" :class="{ empty: item.status == 0 }">
      {{ item.ETD | weeksFormat(LOCALE) }}
    </div>
    <div class="td td2" :class="{ empty: item.status == 0 }">
      {{ item.transitTime }}{{ $t("days") }}
    </div>
    <div class="td td3 textStyle" :class="{ empty: item.status == 0 }">
      <div v-if="item.isTransit == 0">{{ $t("direct") }}</div>
      <div v-if="item.isTransit == 1">
        <div>
          {{ $t("transit") }} （<span
            style="color: #298df8; cursor: pointer"
            @click="handleViewDetail(item)"
            >{{ item.transitNum }}<span v-if="LOCALE == 'zh'">程</span></span
          >）
        </div>
      </div>
    </div>
    <div
      class="td td4 test_over text_overflow_2 textStyle"
      :title="item.wharf"
      :class="{ empty: item.status == 0 }"
    >
      {{ item.wharf }}
    </div>
    <div
      class="td td5 mainColor cursor text_overflow_2 test_over"
      :class="{ empty: item.status == 0 }"
    >
      <span @click="handleViewDetail(item)"
        >{{ item.vessel }} / {{ item.voyage }}</span
      >
    </div>
    <div class="td td6 textStyle">
      <div
        :class="
          item.sevenDataid == 1 ? 's3' : item.sevenDataid == 2 ? 's2' : 's1'
        "
      >
        {{ item.sevenData }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  methods: {
    handleViewDetail(row) {
      this.$emit("handleViewDetail", row);
    },
  },
};
</script>
<style lang="less" scoped>
.textStyle_b {
  font-size: 14px;
  font-weight: 800;
  color: #1f292e;
}

.textStyle {
  font-size: 14px;
  font-weight: 400;
}

.itemBox {
  padding-left: 20px;
  min-height: 62px;
  margin: 8px 0;
  background: #fff;
  display: flex;
  align-items: center;
}

.td {
  //height: 100%
  padding-right: 10px;
  word-wrap: break-word;
}
.td1 {
  width: 128px;
}
.td2 {
  width: 120px;
}
.td3 {
  width: 191px;
}
.td4 {
  width: 360px;
  overflow: hidden;
}
.td5 {
  width: 282px;
}
.td6 {
  flex: 1;
}
.test_over {
  padding-right: 10px;
}

.s1,
.s2,
.s3 {
  width: 73px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: #f8bf29;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  margin: auto;
}

.s2 {
  background: #027fff;
}

.s3 {
  background: #ff5155;
}

.empty {
  color: #999999 !important;
}
</style>
