export const creditClass = [           //信用等级
    {
        value: 1,
        label_zh: 'AAA',
        label_en: 'AAA'
    },
    {
        value: 2,
        label_zh: 'AA',
        label_en: 'AA'
    },
    {
        value: 3,
        label_zh: 'A',
        label_en: 'A'
    }
]

export const unitType = [           //单位类型
    {
        value: 5,
        label_zh: '会长单位',
        label_en: 'President Company'
    },
    {
        value: 4,
        label_zh: "副会长单位",
        label_en: 'Vice President Company'
    },
    {
        value: 3,
        label_zh: '监事单位',
        label_en: 'Supervisor Company'
    },
    {
        value: 2,
        label_zh: '理事单位',
        label_en: 'Director Company'
    },
    {
        value: 1,
        label_zh: '会员单位',
        label_en: 'Member Company'
    }
]
export const cityList = [
    { id: 1, country_id: 1, name_zh: "香港", name_en: "Hong Kong" },
    { id: 2, country_id: 1, name_zh: "澳门", name_en: "Macao" },
    { id: 3, country_id: 1, name_zh: "北京", name_en: "Beijing" },
    { id: 4, country_id: 1, name_zh: "天津", name_en: "Tianjin" },
    { id: 5, country_id: 1, name_zh: "石家庄", name_en: "Shijiazhuang" },
    { id: 6, country_id: 1, name_zh: "唐山", name_en: "Tangshan" },
    { id: 7, country_id: 1, name_zh: "秦皇岛", name_en: "Qinhuangdao" },
    { id: 8, country_id: 1, name_zh: "邯郸", name_en: "Handan" },
]
export const yearList = [           //单位类型
    {
        value:8,
        label_zh:'2021年',
        label_en:'2021'
    },
    {
        value: 1,
        label_zh: '2020年',
        label_en: '2020'
    },
    {
        value: 2,
        label_zh: '2019年',
        label_en: '2019'
    },
    {
        value: 3,
        label_zh: '2018年',
        label_en: '2018'
    },
    {
        value: 4,
        label_zh: '2017年',
        label_en: '2017'
    },
    {
        value: 5,
        label_zh: '2016年',
        label_en: '2016'
    },
    {
        value: 6,
        label_zh: '2015年',
        label_en: '2015'
    },
    {
        value: 7,
        label_zh: '2014年',
        label_en: '2014'
    }
]
export const staffsizeList = [ //企业规模
    {
        value: 1,
        label_en: "1-50 people",
        label_zh: "1-50人"
    },
    {
        value: 2,
        label_en: "51-100 people",
        label_zh: "51-100人"
    },
    {
        value: 3,
        label_en: "101-200 people",
        label_zh: "101-200人"
    },
    {
        value: 4,
        label_en: "201-500 people",
        label_zh: "201-500人"
    },
    {
        value: 5,
        label_en: "501-1000 people",
        label_zh: "501-1000人"
    },
    {
        value: 6,
        label_en: "More than 1001 people",
        label_zh: "1001人及以上"
    }
]
export const weeksList = [ //周末
    {
        value: 1,
        label_zh: "周一",
        label_en: "Monday",
    },
    {
        value: 2,
        label_zh: "周二",
        label_en: "Tuesday",
    },
    {
        value: 3,
        label_zh: "周三",
        label_en: "Wednesday",
    },
    {
        value: 4,
        label_zh: "周四",
        label_en: "Thursday",
    },
    {
        value: 5,
        label_zh: "周五",
        label_en: "Friday",
    },
    {
        value: 6,
        label_zh: "周六",
        label_en: "Saturday",
    },
    {
        value: 0,
        label_zh: "周日",
        label_en: "Sunday",
    }
]


export const positionTypeList = [           //职位类型
    {
        value: 1,
        label_zh: '销售',
        label_en: 'Sales',
      },
      {
        value: 2,
        label_zh: '操作/报关/单证',
        label_en: 'Operation',
      },
      {
        value: 3,
        label_zh: '客服',
        label_en: 'Customer Service',
      },
      {
        value: 4,
        label_zh: '市场/商务',
        label_en: 'Marketing',
      },
      {
        value: 5,
        label_zh: '仓储/运输/配送',
        label_en: 'Warehouse&Delivery',
      },
      {
        value: 6,
        label_zh: '财务',
        label_en: 'Finance',
      },
      {
        value: 7,
        label_zh: '人力资源',
        label_en: ' Human Resource',
      },
      {
        value: 8,
        label_zh: '行政/后勤/文秘',
        label_en: 'Administration/Secretary',
      },
      {
        value: 9,
        label_zh: '采购/外贸',
        label_en: 'Perchase/ Trade',
      },
      {
        value: 10,
        label_zh: '高级管理',
        label_en: 'Senior Management',
      },
      {
        value: 11,
        label_zh: '其他',
        label_en: 'Other',
      }
]