<template>
  <div class="box">
    <div class="boxTop">
      <div class="top1">
        <div class="port" :title="row.pol">{{ row.pol }}</div>
        <div class="port_zh" :title="row.pol_zh">{{ row.pol_zh }}</div>
      </div>
      <div class="top2">
        <div class="top2Class">
          <div class="line"></div>
          <div class="top2Len">{{ row.transitTime }}{{ $t("days") }}</div>
          <div class="line">
            <div class="lineSanJiao"></div>
          </div>
        </div>
        <div class="port_zh textC">
          {{ row.isTransit == 0 ? $t("direct") : $t("transit") }}
        </div>
      </div>
      <div class="top1">

        <div class="port textR" :title="row.pod">{{ row.pod }}</div>
        <div class="port_zh textR" :title="row.pod_zh">{{ row.pod_zh }}</div>
      </div>
    </div>
    <div class="boxCenter">
      <div class="boxCenterTitle">
        RCL:
        <span class="wharf">{{ row.wharf }}</span>
      </div>
    </div>
    <div class="boxContent">
      <div class="item">
        <div class="yuanquan"></div>
        <div class="port">
          {{ $t("departurePorts") }} {{ row.pol }}({{ row.pol_zh }})
        </div>
        <div class="polInfo1">
          <div>{{ $t("plannedDeparture") }}</div>
          <div class="marginL4">{{ row.polInfo1 }}</div>
        </div>
        <div class="polInfo1">
          <div>{{ $t("latestForecast") }}</div>
          <div class="marginL4">{{ row.polInfo2 }}</div>
          <div
            class="polInfo1Status"
            :class="
              row.sevenDataid == 1 ? 's3' : row.sevenDataid == 2 ? 's2' : 's1'
            "
          >
            {{ row.sevenData }}
          </div>
        </div>
        <div class="polInfo2">
          {{ $t('shipName')+"/"+$t('voyage')+"/"+$t('routeCode')}}：
          <span>{{ row.vessel }} / {{ row.voyage }} / {{ row.routeCode }}</span>
        </div>
      </div>
      <div class="item" v-if="row.transitPortEn">
        <div class="yuanquan"></div>
        <div class="port">
          {{$t('transitPort')}} {{ row.transitPortEn }}({{ row.transitPortZh }})
        </div>
      </div>
      <div class="item" v-if="row.secondTransitPortEn">
        <div class="yuanquan"></div>
        <div class="port">
          {{$t('transitPort')}} {{ row.secondTransitPortEn }}({{ row.secondTransitPortZh }})
        </div>
      </div>
      <div class="item" v-if="row.thirdTransitPortEn">
        <div class="yuanquan"></div>
        <div class="port">
          {{$t('transitPort')}} {{ row.thirdTransitPortEn }}({{ row.thirdTransitPortZh }})
        </div>
      </div>
      <div class="item">
        <div class="yuanquan"></div>
        <div class="port">{{$t('destinations')}} {{ row.pod }}({{ row.pod_zh }})</div>
        <div class="polInfo1">
          <div>{{$t('planArrive')}}</div>
          <div class="marginL4">{{ row.podInfo1 }}</div>
        </div>
        <div class="polInfo1">
          <div>{{$t('latestForecast')}}</div>
          <div class="marginL4">{{ row.podInfo2 }}</div>
          <div
            class="polInfo1Status"
            :class="
              row.sevenDataid2 == 1 ? 's3' : row.sevenDataid2 == 2 ? 's2' : 's1'
            "
          >
            {{ row.sevenData2 }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "detail",
  props: {
    row: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {
    console.log(this.row, "row");
  },
};
</script>

<style scoped lang="less">
.box {
  padding: 0 10px;
}
.boxTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .top1 {
    width: 184px;
    div {
      width: 100%;
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis;
    }
  }
  .port {
    font-size: 18px;
    font-weight: 800;
    color: #ff5155;
    line-height: 22px;
  }
  .port_zh {
    margin-top: 4px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 22px;
  }
  .top2 {
    width: 170px;

    .top2Class {
      display: flex;
      align-items: center;

      .top2Len {
        color: #ff5155;
        font-size: 14px;
        padding: 0 18px;
      }

      .line {
        width: 44px;
        height: 1px;
        border: 1px solid #979797;
        position: relative;

        .lineSanJiao {
          position: absolute;
          right: -10px;
          top: -4px;
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-left: 10px solid #979797;
          border-bottom: 5px solid transparent;
        }
      }
    }
  }
  .textC {
    text-align: center;
  }
  .textR {
    text-align: right;
  }
}
.boxCenter {
  width: 100%;
  padding: 12px;
  background: #fff6f6;
  margin-top: 20px;
  .boxCenterTitle {
    font-weight: bold;
    color: #1f292e;
  }
  .wharf {
    color: #1f292e;
    font-weight: 400;
    margin-left: 4px;
  }
}
.boxContent {
  margin-top: 20px;
}
.item {
  position: relative;
  border-left: 2px #ececec solid;
  padding-left: 20px;
  padding-bottom: 24px;
  .yuanquan {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #ffffff;
    border: 1px solid #ff5155;
    position: absolute;
    top: 0px;
    left: -7px;
  }
  .port {
    font-weight: 800;
    color: #333333;
    font-size: 16px;
    line-height: 22px;
    position: relative;
    top: -5px;
  }
  .polInfo1 {
    display: flex;
    align-items: center;
    color: #333333;
    margin-top: 8px;
    .polInfo1Status {
      margin-left: 26px;
      padding: 2px 18px;
      color: #ffffff;
      font-size: 11px;
      font-weight: bold;
    }
  }
  .polInfo2 {
    padding: 10px 15px;
    background: #eeefed;
    border-radius: 6px;
    width: 100%;
    margin-top: 12px;
  }
}
.item:last-child {
  border-left: none;
  padding-left: 22px;
  .yuanquan {
    left: -5px;
  }
}
.marginL4 {
  margin-left: 4px;
}
.s1 {
  background: #f8bf29;
}
.s2 {
  background: #027fff;
}

.s3 {
  background: #ff5155;
}
</style>