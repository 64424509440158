<template>
  <div>
    <el-autocomplete
        v-model="code"
        :placeholder="placeTitle"
        :size="size"
        :fetch-suggestions="remoteMethod"
        @select="handleSelect"
        :clearable="clearable"
        :disabled="disabled"
        popper-class="portSerachClass"
        :title="code"
    >
      <template slot-scope="{ item }">
        <div class="searchBox">
          <div class="boxLeft">
            <p>{{ item.name_en }}</p>
            <p>{{ item.name_zh }}</p>
          </div>
          <div>
            {{ item.country_id | countryCityFormat(item.name_en,item.name_zh,LOCALE) }}
          </div>
        </div>
      </template>
    </el-autocomplete>
  </div>
</template>


<script>
export default {
  name: "portSearch",
  props: {
    placeTitle: {
      type: String,
      default: "",
    },
    value: {
      type: String | Number,
      default: null,
    },
    size: {
      type: String,
      default: "small",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    searchTopBg: {
      type: String,
      default: '#fff'
    },
    // pol是起运港，pod是目的港
    portType: {
      type: String,
      default: 'pol'
    }
  },
  data() {
    return {
      code: null,
      options: [],
      loading: false
    }
  },
  watch: {
    code(nv) {
      this.$emit("input", nv);
      if (!nv) {
        this.$emit("codeChange", '');
      }
    },
    value(nv) {
      this.code = nv;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.code = this.value
    })
  },
  methods: {
    async remoteMethod(queryString, cb) {
      try {
        let data
        if (queryString) {
          // 搜索港口
          let params = {
            name: queryString,
          };
          if (this.portType === 'pol') {
            params.is_china = '1'
          }
          if (this.portType === 'pod') {
            params.is_china = '0'
          }
          data = await this.$store.dispatch("baseStore/shipdataGetHarbour", params)
        } else {
          // 热门港口
          data = await this.$store.dispatch("baseStore/shipdataHotHarbour", {})
          if (this.portType === 'pol') {
            data.data = data.zh_hot_ports
          }
          if (this.portType === 'pod') {
            data.data = data.not_zh_hot_ports
          }
        }
        if (data.success) {
          data.data.forEach(item => {
            item.value = item.name_en + '(' + item.name_zh + ')'
          })
          this.options = data.data
          cb (data.data)
        } else {
          cb([]);
        }
      } catch {
      }
    },
    handleSelect(item) {
      this.$emit("codeChange", item.code);
    },
  },
}
</script>

<style scoped lang="less">
.searchBox {
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .boxLeft {
    line-height: 200%;
  }
}
/deep/ .el-input__inner {
  background: var(--searchTopBg)!important;
}
/deep/ input::-webkit-input-placeholder {
  color: #7C7C7C;
  font-size: 15px;
}
/deep/  input::-moz-input-placeholder {
  color: #7C7C7C;
  font-size: 15px;
}
/deep/  input::-ms-input-placeholder {
  color: #7C7C7C;
  font-size: 15px;
}
.el-autocomplete {
  /deep/ input {
    font-size: 15px;
    color: #000000;
  }
}
</style>
<style lang="less">
.portSerachClass /deep/ {
  width: 320px!important;
}
</style>
